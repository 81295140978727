import { stringify } from 'querystring';
import { call, cancelled, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { NormalizedEntities } from '../normalizers';
import { normalizeIssueLabel } from '../normalizers/issue-label';
import { alert } from '../store/alerts';
import { remove, set, set_loading, update } from '../store/issue-labels';
import { IIssueLabel, IJsonLdCollection } from '../types';
import { api } from '../utils';

export const GET_ISSUE_LABEL = 'saga/issue-label/get';
export const GET_ISSUE_LABELS = 'saga/issue-labels/get';
export const DELETE_ISSUE_LABEL = 'saga/issue-labels/delete';

interface GetLabelAction {
    type: typeof GET_ISSUE_LABEL;
    payload: IIssueLabel['id'];
}

interface GetLabelsAction {
    type: typeof GET_ISSUE_LABELS;
    payload: {
        projectId: number;
        params: {
            page?: number;
            pageSize?: number;
        };
    };
}

interface deleteIssueLabel {
    type: typeof DELETE_ISSUE_LABEL;
    payload: IIssueLabel['id'];
}

export function* getIssueLabel(action: GetLabelAction): Generator {
    const abortController = new AbortController();

    yield put(set_loading(true));

    try {
        const label = (yield call(api, `/api/issues/issue_labels/${action.payload}.json`, {
            signal: abortController.signal,
        })) as IIssueLabel;

        yield put(update({ byId: { [label.id]: label }, entities: [label.id] }));
    } catch (e) {
        yield put(alert((e as Error).message));
    } finally {
        if (yield cancelled()) {
            yield abortController.abort();
        }

        yield put(set_loading(false));
    }
}

export function* getIssueLabels(action: GetLabelsAction): Generator {
    const abortController = new AbortController();

    yield put(set_loading(true));

    try {
        const queryParams = yield call(stringify, action.payload.params);
        const response = (yield call(api, `/api/issues/projects/${action.payload.projectId}/labels?${queryParams}`, {
            signal: abortController.signal,
        })) as IJsonLdCollection<IIssueLabel>;
        const labels = (yield call(normalizeIssueLabel, response['hydra:member'])) as NormalizedEntities<IIssueLabel>;

        yield put(
            set({
                byId: labels.entities.byId,
                entities: labels.result,
                count: response['hydra:totalItems'],
            })
        );
    } catch (e) {
        yield put(alert((e as Error).message));
    } finally {
        if (yield cancelled()) {
            yield abortController.abort();
        }

        yield put(set_loading(false));
    }
}

export function* deleteIssueLabel(action: deleteIssueLabel): Generator {
    yield put(set_loading(true));

    try {
        yield call(api, `/api/issues/issue_labels/${action.payload}.json`, {
            method: 'DELETE',
        });

        yield put(remove([action.payload]));
    } catch (e) {
        yield put(alert((e as Error).message));
    } finally {
        yield put(set_loading(false));
    }
}

function* issueLabelSaga(): Generator {
    yield takeLatest(GET_ISSUE_LABEL, getIssueLabel);
    yield takeLatest(GET_ISSUE_LABELS, getIssueLabels);
    yield takeEvery(DELETE_ISSUE_LABEL, deleteIssueLabel);
}

export default issueLabelSaga;
